export const ADD_GAME = "ADD_GAME";
export const DELETE_GAME = "DELETE_GAME";
export const UNDO = "UNDO";
export const ADD_ROUND = "ADD_ROUND";
export const DELETE_ROUND = "DELETE_ROUND";
export const SET_THEME = "SET_THEME";
export const SET_LOCALE = "SET_LOCALE";
export const SET_USERNAME = "SET_USERNAME";
export const ADD_CUSTOM_RULESET = "ADD_CUSTOM_RULESET";
export const REMOVE_CUSTOM_RULESET = "REMOVE_CUSTOM_RULESET";
